<template>
  <div class="widget-design-earnings box-border flex w-full flex-col p-16" @click="openOfferwall">
    <div class="mb-16 mt-10 flex items-center">
      <svg-earnings-bag :gradient-id="gradientId" class="mr-10" />
      <div v-t="'widgetDesignEarningsTitle'" class="text-17 font-semibold" />
    </div>
    <div class="grid w-full grid-cols-2 gap-10">
      <div v-for="(earning, index) in tiles" :key="index" class="flex w-full flex-col items-center">
        <div
          v-t="earning.title"
          class="text-13 rounded-tr-4 rounded-tl-4 flex w-full items-center justify-center bg-gray-50 py-10 font-semibold dark:bg-gray-800"
        />
        <div
          class="rounded-bl-4 rounded-br-4 bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 flex w-full items-center justify-center py-10"
        >
          <bb-skeleton-item v-if="isFetching" class="h-24 w-[3.5rem]" />
          <currency-amount v-else :amount="earning.value" icon-size="small" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BbSkeletonItem } from '@bitburst-gmbh/bitburst-ui';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useEarningsStore } from '../../../stores/earningsStore';
import CurrencyAmount from '../../CurrencyAmount.vue';
import SvgEarningsBag from '../../svgs/SvgEarningsBag.vue';

defineProps<{
  gradientId: string;
}>();

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const earningsStore = useEarningsStore();
const { isFetching, today, pastThirtyDays } = storeToRefs(earningsStore);
void earningsStore.fetchEarnings();

const tiles = computed(() => {
  return [
    {
      title: 'widgetDesignEarningsPastThirtyDays',
      value: pastThirtyDays.value,
    },
    {
      title: 'widgetDesignEarningsToday',
      value: today.value,
    },
  ];
});
</script>
