// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Api } from '../api';

const useEarningsStore = defineStore('earnings', () => {
  const isFetching = ref(false);
  const today = ref(0);
  const pastThirtyDays = ref(0);

  const fetchEarnings = async () => {
    isFetching.value = true;
    const data = await Api.Bitlabs.v1.getUserHistory().then(response => {
      const data = response.data.data;
      if (!data) return { today: 0, total: 0 };

      const today = new Date().setHours(0, 0, 0, 0);
      const thirtyDaysAgo = new Date(new Date().getDate() - 30).setHours(0, 0, 0, 0);
      const rewardedHistoryPastThirtyDays = data.filter(
        historyItem =>
          (historyItem.type === 'COMPLETE' || historyItem.type === 'SCREENOUT') && // filter only completes and screenouts
          !historyItem.reconciled && // filter only unreconciled
          new Date(historyItem.created_at).getTime() >= thirtyDaysAgo // filter only last 30 days
      );
      const todayHistory = rewardedHistoryPastThirtyDays.filter(
        historyItem => today === new Date(historyItem.created_at).setHours(0, 0, 0, 0)
      );

      return {
        today: todayHistory.reduce((a, b) => a + Number(b.user_value), 0),
        pastThirtyDays: rewardedHistoryPastThirtyDays.reduce((a, b) => a + Number(b.user_value), 0),
      };
    });

    today.value = data.today;
    pastThirtyDays.value = data.pastThirtyDays;
    isFetching.value = false;
  };

  return { isFetching, today, pastThirtyDays, fetchEarnings };
});

export { useEarningsStore };
